<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async biddingChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]

        xPadding = 0.3, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        numberSuffix = "", //数字的后缀
        numberPosition = "inside", //数字显示在柱子里边还是外边
        labelPosition = "left", //label的位置在左边还是上边
        isFromZero = true, //是否从0开始
        isNegativeSameDirection = false, //默认负值柱子和正值柱子不同方向
        barRadius = 0, //柱子的圆角
        valueSuffix = "",
      } = {}
    ) {
      this.duration = duration;
      console.log(data);

      const X = data.map((d) => d.label);
      const Y = data.map((d) => d.value);

      //domain是数值范围
      //range是画图范围
      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, d3.max(Y) * 1.1];
      yDomain = new d3.InternSet(yDomain);

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);

      this.xDomain = xDomain;
      this.xScale = xScale;
      this.ease = ease;

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画线
      const drawLine = () => {
        const lineGroup = svg.append("g").attr("class", "line_group");
        lineGroup
          .append("line")
          .attr("class", "x_axis")
          .attr("x1", marginLeft)
          .attr("y1", height - marginBottom)
          .attr("x2", width - marginRight)
          .attr("y2", height - marginBottom)
          .attr("stroke", "#707070")
          .attr("stroke-width", 2);
        lineGroup
          .append("line")
          .attr("class", "y_axis")
          .attr("x1", width / 2)
          .attr("y1", marginTop)
          .attr("x2", width / 2)
          .attr("y2", height - marginBottom)
          .attr("stroke-dasharray", "5,5")
          .attr("stroke", "#707070")
          .attr("stroke-width", 2);
      };
      drawLine();

      //画label
      const drawLabel = () => {
        const xAxis = svg
          .append("g")
          .attr("class", "x_axis")
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("x", (d, i) => {
            if (i === 0 || i === 3) {
              return xScale(X[i]) + xScale.bandwidth() / 2;
            } else if (i === 1) {
              return xScale(X[i]) + xScale.bandwidth() / 6;
            } else if (i === 2) {
              return xScale(X[i]) + (5 * xScale.bandwidth()) / 6;
            }
          })
          .attr("y", height - marginBottom)
          .attr("dy", "1.3em")
          .text((d, i) => X[i])
          .attr("text-anchor", "middle")
          .attr("class", (d, i) => {
            if (i == 0 || i == 2) {
              return "label much_label";
            } else {
              return "label empty_label";
            }
          })
          .attr("fill", (d, i) => {
            if (i == 0 || i == 2) {
              return "#FF3B30";
            } else {
              return "#39B24E";
            }
          })
          .attr("opacity", 0);

        xAxis
          .transition()
          .delay(function (d, i) {
            return i * delay;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("opacity", 1);
      };
      drawLabel();

      //画柱子
      const drawBar = () => {
        const bar = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("x", (d, i) => {
            if (i === 0 || i === 3) {
              return xScale(X[i]);
            } else if (i === 1) {
              return xScale(X[i]) - xScale.bandwidth() / 3;
            } else if (i === 2) {
              return xScale(X[i]) + xScale.bandwidth() / 3;
            }
          })
          .attr("y", (d, i) => yScale(0))
          .attr("rx", barRadius)
          .attr("ry", barRadius)
          .attr("class", (d, i) => {
            if (i == 0 || i == 2) {
              return "bar much_bar";
            } else {
              return "bar empty_bar";
            }
          })
          .attr("fill", (d, i) => {
            if (i == 0 || i == 2) {
              return "#FF3B30";
            } else {
              return "#39B24E";
            }
          })
          .attr("width", xScale.bandwidth())
          .attr("height", 0);
        //柱子动画
        bar
          .transition()
          .delay(function (d, i) {
            return i * delay ;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("y", (d, i) => yScale(Y[i]))
          .attr("height", (d, i) => {
            return yScale(0) - yScale(Y[i]);
          });
      };
      drawBar();

      //画value
      const drawValue = async () => {
        const valueGroup = svg
          .append("g")
          .attr("class", "value_group")
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("x", (d, i) => {
            if (i === 0 || i === 3) {
              return xScale(X[i]) + xScale.bandwidth() / 2;
            } else if (i === 1) {
              return xScale(X[i]) + xScale.bandwidth() / 6;
            } else if (i === 2) {
              return xScale(X[i]) + (5 * xScale.bandwidth()) / 6;
            }
          })
          .attr("y", (d, i) => yScale(0))
          .attr("dy", -13)
          .attr("class", (d, i) => {
            if (i == 0 || i == 2) {
              return "value much_value";
            } else {
              return "value empty_value";
            }
          })
          .text((d, i) => Y[i] + valueSuffix)
          .attr("text-anchor", "middle")
          .attr("fill", (d, i) => {
            if (i == 0 || i == 2) {
              return "#FF3B30";
            } else {
              return "#39B24E";
            }
          })
          .attr("opacity", 0);

        valueGroup
          .transition()
          .delay(function (d, i) {
            return i * delay;
          })
          .duration(duration)
          .attr("y", (d, i) => yScale(Y[i]))
          .ease(d3[ease + "Out"])
          .attr("opacity", 1);
      };
      drawValue();

      $("#" + this.id).html(svg.node());
    },
    async northFundsIn(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]

        xPadding = 0.3, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        numberSuffix = "", //数字的后缀
        numberPosition = "inside", //数字显示在柱子里边还是外边
        labelPosition = "left", //label的位置在左边还是上边
        isFromZero = true, //是否从0开始
        isNegativeSameDirection = false, //默认负值柱子和正值柱子不同方向
        barRadius = 0, //柱子的圆角
        valueSuffix = "",
        title = [],
      } = {}
    ) {
      this.duration = duration;
      console.log(data);

      const X = data.map((d) => d.label);
      const Y = data.map((d) => d.value);

      //domain是数值范围
      //range是画图范围
      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, d3.max(Y) * 1.2];
      yDomain = new d3.InternSet(yDomain);

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);

      this.xDomain = xDomain;
      this.xScale = xScale;
      this.ease = ease;

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画标题
      const drawTitle = () => {
        const titleGroup = svg
          .append("g")
          .attr("class", "title_group")
          .selectAll("text")
          .data(title)
          .join("text")
          .attr(
            "x",
            (d, i) =>
              marginLeft +
              ((width - marginLeft - marginRight) / 4) * (2 * i + 1)
          )
          .attr("y", marginTop)
          .text((d, i) => title[i])
          .attr("text-anchor", "middle")
          .attr("dy", "1em")
          .attr("opacity", 0)
          .transition()
          .delay(function (d, i) {
            return delay * (data.length / 2) * i;
          })
          .duration(duration)
          .attr("opacity", 1);
      };
      if (title.length > 0) {
        drawTitle();
      }

      //画线
      const drawLine = () => {
        const lineGroup = svg.append("g").attr("class", "line_group");
        lineGroup
          .append("line")
          .attr("class", "x_axis")
          .attr("x1", marginLeft)
          .attr("y1", height - marginBottom)
          .attr("x2", width - marginRight)
          .attr("y2", height - marginBottom)
          .attr("stroke", "#707070")
          .attr("stroke-width", 2);
        lineGroup
          .append("line")
          .attr("class", "y_axis")
          .attr("x1", width / 2)
          .attr("y1", marginTop)
          .attr("x2", width / 2)
          .attr("y2", height - marginBottom)
          .attr("stroke-dasharray", "5,5")
          .attr("stroke", "#707070")
          .attr("stroke-width", 2);
      };
      drawLine();

      //画label
      const drawLabel = () => {
        const xAxis = svg
          .append("g")
          .attr("class", "x_axis")
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("x", (d, i) => {
            if (i < data.length / 2) {
              return (
                xScale(X[i]) -
                (xScale.bandwidth() / 6) * i +
                xScale.bandwidth() / 2
              );
            } else {
              return (
                xScale(X[i]) +
                (xScale.bandwidth() / 6) * (data.length - 1 - i) +
                xScale.bandwidth() / 2
              );
            }
          })
          .attr("y", height - marginBottom)
          .attr("dy", "1.3em")
          .text((d, i) => X[i])
          .attr("text-anchor", "middle")
          .attr("class", (d, i) => {
            if (i < data.length / 2) {
              return "label label_in";
            } else {
              return "label label_out";
            }
          })
          .attr("fill", (d, i) => {
            if (i < i < data.length / 2) {
              return "#FF3B30";
            } else {
              return "#39B24E";
            }
          })
          .attr("opacity", 0);

        xAxis
          .transition()
          .delay(function (d, i) {
            return i * delay;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("opacity", 1);
      };
      drawLabel();

      //画柱子
      const drawBar = () => {
        const bar = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("x", (d, i) => {
            if (i < data.length / 2) {
              return xScale(X[i]) - (xScale.bandwidth() / 6) * i;
            } else {
              return (
                xScale(X[i]) + (xScale.bandwidth() / 6) * (data.length - 1 - i)
              );
            }
          })
          .attr("y", (d, i) => yScale(0))
          .attr("rx", barRadius)
          .attr("ry", barRadius)
          .attr("class", (d, i) => {
            if (i < data.length / 2) {
              return "bar bar_in";
            } else {
              return "bar bar_out";
            }
          })
          .attr("fill", (d, i) => {
            if (i < data.length / 2) {
              return "#FF3B30";
            } else {
              return "#39B24E";
            }
          })
          .attr("width", xScale.bandwidth())
          .attr("height", 0);
        //柱子动画
        bar
          .transition()
          .delay(function (d, i) {
            return i * delay;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("y", (d, i) => yScale(Y[i]))
          .attr("height", (d, i) => {
            return yScale(0) - yScale(Y[i]);
          });
      };
      drawBar();

      //画value
      const drawValue = async () => {
        const valueGroup = svg
          .append("g")
          .attr("class", "value_group")
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("x", (d, i) => {
            if (i < data.length / 2) {
              return (
                xScale(X[i]) -
                (xScale.bandwidth() / 6) * i +
                xScale.bandwidth() / 2
              );
            } else {
              return (
                xScale(X[i]) +
                (xScale.bandwidth() / 6) * (data.length - 1 - i) +
                xScale.bandwidth() / 2
              );
            }
          })
          .attr("y", (d, i) => yScale(0))
          .attr("dy", -13)
          .attr("class", (d, i) => {
            if (i < data.length / 2) {
              return "value value_in";
            } else {
              return "value value_out";
            }
          })
          .text((d, i) => Y[i] + valueSuffix)
          .attr("text-anchor", "middle")
          .attr("fill", (d, i) => {
            if (i < data.length / 2) {
              return "#FF3B30";
            } else {
              return "#39B24E";
            }
          })
          .attr("opacity", 0);

        valueGroup
          .transition()
          .delay(function (d, i) {
            return i * delay ;
          })
          .duration(duration)
          .attr("y", (d, i) => yScale(Y[i]))
          .ease(d3[ease + "Out"])
          .attr("opacity", 1);
      };
      drawValue();

$("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
